import { useQuery } from '@tanstack/react-query';

const queryKey = '/v1/thumbnail/get-screenshot';

const REACT_APP_THUMBNAIL_URL = `https://thumbnail.uapi.newfold.com${queryKey}`; // PROD
// const REACT_APP_THUMBNAIL_URL = `https://thumbnail-beta.uapi.newfold.com${queryKey}`; // BETA

const REACT_APP_THUMBNAIL_JWT =
  'eyJhbGciOiJSUzI1NiIsImtpZCI6InRodW1ibmFpbCIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1cm46amFydmlzOmJsdWVob3N0Iiwic2NvcGUiOiJ1c2VyLWZlIiwiYWN0Ijp7InN1YiI6ImphcnZpczpibHVlaG9zdDp1c2VyOnRlc3R1c2VyIiwicm9sZSI6ImFkbWluIn0sImF1ZCI6IlFBIiwiZXhwIjoxOTk2MTY4NDM2LCJpYXQiOjE2ODA1OTI0MzZ9.RDo_m2PP18ZAduFbCM1FVfDFpAh41785DtTkxRotN8qLcce95FZN8JkMiZdY7FOVzYLpAoY8bX6I8jcD7Libz1Q1Q02Qxy1vLX5-CUuwQ9VOioqs6637gBDspmO9ZjKj7Qy4DNnSzVW_e6qQQye9ZGDmeI1HsSm9LYhtTM6sPI7maSqMtCfCnJNJGHW0nO3AI1s4m11f6cxYHATypol6mNcVb1Jw7ex9e4-azWWOdESPotGxTJaA4kWDMrxIRR1Sg_0RLuurjvXMDLqCKarYGCH-JhDBIjyNS8j1ikTYY9rhPzim438u1Cp-txfPzMlopOSIW5zBuc5X7B9g2y8-TQ'; // PROD
// const REACT_APP_THUMBNAIL_JWT = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6InRodW1ibmFpbCJ9.eyJzdWIiOiJ1cm46amFydmlzOmJsdWVob3N0Iiwic2NvcGUiOiJ1c2VyLWZlIiwiYWN0Ijp7InN1YiI6ImphcnZpczpibHVlaG9zdDp1c2VyOnRlc3R1c2VyIiwicm9sZSI6ImFkbWluIn0sImF1ZCI6IlFBIiwiZXhwIjoxOTc4NTU3NTcyLCJpYXQiOjE2NjI5ODE1NzJ9.yN0fYk3pCTy0TrQup6hT3K5KM2USa1l5hfvusirkdERbySklf6HwK_7kEx3Bq3Y_WDOiQglPlns5J1WNkuHY7FFLPEnnSBb0hhMoHt5syqxfKe9Xc5JYoeJEky2xuaGBTopGJsqGViAqld50jDGxnz9zSlT3zmSc8lnVldaG7hxKUm6a7ILUuRCz-fMEzhP69nxlHfjakLQwnHMKK2Sa0Zu548vFLOTIg3aG_Aegx4dHG71sM2zBsue0Oq_2Z_EMly3EcOg8paUxPDjgStB2nM0y6oXHH28d-hdpAL_trBiNania5poseMTFsSU1QunI4ouGITZOlh1AZ8bepJeLqg'; // BETA

type siteUrlTypes = string | undefined | null;

const fetchThumbnail = (siteUrl: siteUrlTypes, override: boolean) => {
  // if no siteurl resolve the promise without returning a url
  if (!siteUrl || typeof siteUrl !== 'string') return Promise.resolve({});

  // add http:// to begining of siteUrl if it doesn't exist
  const siteUrlFormatted =
    siteUrl.indexOf('http') === 0 ? siteUrl : `http://${siteUrl}`;

  // return the fetch promise
  return fetch(
    `${REACT_APP_THUMBNAIL_URL}?site_url=${siteUrlFormatted}&force_override=${override}`,
    {
      headers: {
        Authorization: `Bearer ${REACT_APP_THUMBNAIL_JWT}`,
      },
    },
  ).then((res) => res.json());
};

const useThumbnail = (
  siteUrl: siteUrlTypes,
  override = false,
  enable = true,
) => {
  const results = useQuery(
    [queryKey, siteUrl],
    () => fetchThumbnail(siteUrl, override),
    {
      enabled: enable,
      refetchInterval: (data) => (data?.data?.thumbnail_loc === '' ? 10000 : 0),
    },
  );
  const thumbnailLoc = results?.data?.thumbnail_loc;
  const message = results?.data?.msg;
  const inProgress = message === 'Thumbnail Generation in progress.' ? 1 : 0;
  const thumbnailType = results?.data?.thumbnail_type;
  return { ...results, thumbnailLoc, thumbnailType, message, inProgress };
};

export default useThumbnail;
