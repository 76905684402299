import { PropsWithChildren } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

type ToolsListProps = {
  pluginsList?: string[];
};

const ToolsList = ({ pluginsList = [] }: PropsWithChildren<ToolsListProps>) => {
  let firstColumnPlugins = pluginsList.slice(0, 3);
  let secondColumnPlugins = pluginsList.slice(3);
  return (
    <>
      <Grid item xs={12} sm={4}>
        <List>
          {firstColumnPlugins.map((item: string, index: number) => (
            <ListItem key={index} disablePadding>
              <ListItemIcon>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} sm={4}>
        <List>
          {secondColumnPlugins.map((item: string, index: number) => (
            <ListItem key={index} disablePadding>
              <ListItemIcon>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </>
  );
};

export default ToolsList;
