import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { HostingAddons200RowsItem } from '@newfold/huapi-js/src/index.schemas';

import SsoLink from '~/components/SsoLink';
import { useSiteContext } from '~/scenes/Site';

import ToolsList from './ToolsList';

type InfoCardProps = {
  addonsList?: HostingAddons200RowsItem[];
};

const InfoCard = ({ addonsList = [] }: InfoCardProps) => {
  const { t } = useTranslation('site');
  const { isDisabled } = useSiteContext();
  const { siteId } = useParams();

  const wordpressToolsCreator = useMemo(
    () =>
      addonsList?.filter(
        (addon) => addon?.subtype === 'creator' && addon?.status === 'active',
      ),
    [addonsList],
  );

  const wordpressToolsService = useMemo(
    () =>
      addonsList?.filter(
        (addon) => addon?.subtype === 'service' && addon?.status === 'active',
      ),
    [addonsList],
  );

  const wordpressToolsCommerce = useMemo(
    () =>
      addonsList?.filter(
        (addon) => addon?.subtype === 'commerce' && addon?.status === 'active',
      ),
    [addonsList],
  );

  const creatorPluginsList: string[] = t('wordPressTools.creatorPluginsList', {
    returnObjects: true,
  });

  const servicePluginsList: string[] = t('wordPressTools.servicePluginsList', {
    returnObjects: true,
  });

  const commercePluginsList: string[] = t(
    'wordPressTools.commercePluginsList',
    {
      returnObjects: true,
    },
  );

  if (Array.isArray(addonsList) && !addonsList.length) {
    return null;
  }

  return (
    <Card data-testid="wordpress-tools-card">
      <CardHeader title={t('wordPressTools.cardTitle')} />
      <CardContent>
        <Stack spacing={1}>
          <Typography>{t('wordPressTools.cardDesc')}</Typography>
          <Grid container>
            {wordpressToolsCreator?.length! > 0 && (
              <ToolsList pluginsList={creatorPluginsList}></ToolsList>
            )}

            {wordpressToolsService?.length! > 0 && (
              <ToolsList pluginsList={servicePluginsList}></ToolsList>
            )}

            {wordpressToolsCommerce?.length! > 0 && (
              <ToolsList pluginsList={commercePluginsList}></ToolsList>
            )}

            <Grid
              item
              xs={12}
              sm={4}
              display="flex"
              alignItems="center"
              justifyContent={'right'}
            >
              <SsoLink
                variant="outlined"
                type="site"
                disabled={!siteId || isDisabled}
                queryParams={{ app: 'plugins' }}
              >
                {t('wordPressTools.pluginsAndToolsButton')}
              </SsoLink>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default InfoCard;
