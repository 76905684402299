import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';

import { useHostingAccount } from '@newfold/huapi-js';
import { HostingAddons200RowsItem } from '@newfold/huapi-js/src/index.schemas';

import CTB from '~/components/CTB';
import HighlightedBox from '~/components/HighlightedBox';
import { useMFEContext } from '~/components/MFEProvider';
import useAccount from '~/hooks/useAccount';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useFeature from '~/hooks/useFeature';
import { useSiteContext } from '~/scenes/Site';

type CtbLinkProps = {
  addonsList?: HostingAddons200RowsItem[];
};
export const CtbLink = ({ addonsList = [] }: CtbLinkProps) => {
  const { t } = useTranslation('site');
  const { id: hostingId } = useAccount();
  const [isAtomic] = useFeature('atomic');
  const { palette } = useTheme();
  const { brandFromJWT, wpSolutionLearnMoreUrl } = useBrandInfo();
  const isBlueHost = brandFromJWT.toLowerCase() === 'bluehost';
  const { isSiteOrHostingDisabled: isDisabled } = useSiteContext();

  const wordPressTools = useMemo(
    () =>
      addonsList?.filter(
        (addon) => addon?.subtype === 'creator' || addon?.subtype === 'service',
      ),
    [addonsList],
  );

  // If creator or service addon then, upgrade possible.
  const canUpgrade = wordPressTools?.length! > 0;

  const addonBackReference = wordPressTools[0]?.back_reference;

  const { data: hostingAccountData, isLoading } = useHostingAccount(hostingId, {
    query: {
      enabled: !!hostingId,
      select: ({ data }) => data,
    },
  });

  // @ts-ignore
  const { data: mfeData } = useMFEContext();

  const cloudPlanTier = hostingAccountData?.plan_key?.replace(
    'atomic_bundle_',
    '',
  );
  const white = palette?.common?.white;
  const planName =
    hostingAccountData?.billing?.product_name ||
    mfeData?.hostingData?.productName;

  const atomicPlanName =
    hostingAccountData?.billing?.product_name ||
    `Cloud ${cloudPlanTier}` ||
    mfeData?.hostingData?.productName;

  const UpgradeText = () => {
    return (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        <Typography variant="body2">{t('wordPressTools.toolsDesc')}</Typography>
        {isBlueHost && (
          <Link href={wpSolutionLearnMoreUrl} target="_blank">
            <Stack direction={'row'}>
              <Typography variant="body2">
                {t('wordPressTools.learnMoreLink')}
              </Typography>
              <ArrowRightAltIcon />
            </Stack>
          </Link>
        )}
      </Stack>
    );
  };

  return (
    <Stack spacing={1} data-testid="wordpress-tools-ctb">
      <Typography variant="h3">{t('wordPressTools.hostingPlan')}</Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={'center'}
        spacing={2}
      >
        <Typography variant="body2">
          {isLoading ? (
            <Skeleton variant="text" height={30} width={100} />
          ) : isAtomic ? (
            atomicPlanName
          ) : (
            planName
          )}
        </Typography>
        {canUpgrade ? (
          <CTB
            product="WP_SOLUTION_UPGRADE"
            data={{
              prodInstId: addonBackReference,
            }}
            disabled={isDisabled}
          >
            {/* @ts-ignore */}
            <Typography variant="h4" color={isAtomic ? white : 'primary'}>
              {t('wordPressTools.manageButton')}
            </Typography>
          </CTB>
        ) : (
          <CTB product="WP_SOLUTION_FAMILY" disabled={isDisabled}>
            {/* @ts-ignore */}
            <Typography variant="h4" color={isAtomic ? white : 'primary'}>
              {t('wordPressTools.manageButton')}
            </Typography>
          </CTB>
        )}
        {isAtomic ? (
          <UpgradeText />
        ) : (
          <HighlightedBox>
            <UpgradeText />
          </HighlightedBox>
        )}
      </Stack>
    </Stack>
  );
};

export default CtbLink;
