// Clean up component comments, commented out code, and sx props
// import {} from 'prop-types';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

import { useHostingAddons, useSitesInfo } from '@newfold/huapi-js';

import useAccount from '~/hooks/useAccount';
import { useBrandInfo } from '~/hooks/useBrandInfo';
import useFeature from '~/hooks/useFeature';
import useQueryFeature from '~/hooks/useQueryFeature';

import WordPressToolsCard from '../../components/WordPressTools/InfoCard';
import OverviewCard from './components/OverviewCard';
import QuickConnect from './components/QuickConnect';
import QuickLinks from './components/QuickLinks';

//import UsageStats from '../../../Hosting/scenes/HostingDetail/components/UsageStats';
//import AdvancedServerInfo from '../../../Hosting/scenes/HostingDetail/components/AdvancedServerInfo';

const Overview = () => {
  const { siteId } = useParams();
  const { id: hostingId } = useAccount();
  const { brandFromJWT } = useBrandInfo();
  const isBlueHost = brandFromJWT.toLowerCase() === 'bluehost';
  const [isWPSolution] = useQueryFeature('wpsolution');
  const { isInitialLoading, isError, data } = useSitesInfo(Number(siteId));
  const [hasAdvancedHosting] = useFeature('advanced_hosting');
  // TODO: flatten this
  const siteInfo = data?.data;
  const isWordPress = siteInfo?.type === 'wordpress';
  const { data: addonList } = useHostingAddons(
    hostingId,
    {
      type: 'wpsolution',
      status: 'active',
    },
    {
      query: {
        enabled:
          !!hostingId &&
          isWordPress &&
          isWPSolution &&
          isBlueHost &&
          !hasAdvancedHosting,
      },
    },
  );

  const siteAddonsList = addonList?.data?.rows?.filter(
    (addon) => addon?.meta?.site_id === siteId,
  );

  const wordpressCommerceTools = useMemo(
    () =>
      siteAddonsList?.filter(
        (addon) => addon?.subtype === 'commerce' && addon?.status === 'active',
      ),
    [siteAddonsList],
  );

  // Does this site have any wpsolutions addons?
  const hasWPSolutions = siteAddonsList?.length! > 0;

  //Does this site have any Wordpress Commerce addon ?
  const hasWordpressCommerceTools = wordpressCommerceTools?.length! > 0;

  const showWordPressToolsCard =
    isWPSolution &&
    isWordPress &&
    isBlueHost &&
    !hasAdvancedHosting &&
    hasWPSolutions;

  const showWordPressToolsCtbLink =
    isWPSolution &&
    isWordPress &&
    isBlueHost &&
    !hasAdvancedHosting &&
    !hasWordpressCommerceTools;

  return (
    <>
      <Box sx={{ transition: '2s' }}>
        {!isError && (
          <Grid container spacing={3} columns={12} data-testid="site-overview">
            <Grid item xs={12} className="xyz-nested">
              <OverviewCard
                loading={isInitialLoading}
                siteInfo={siteInfo}
                showWordPressToolsCtbLink={showWordPressToolsCtbLink}
                addonsList={siteAddonsList}
              />
            </Grid>

            {showWordPressToolsCard && (
              <Grid item xs={12} className="xyz-nested">
                <WordPressToolsCard addonsList={siteAddonsList} />
              </Grid>
            )}

            {/* Dummy Component added for alignment with just the headers */}
            <Grid item xs={12} md={8} className="xyz-nested">
              <Card>
                <CardContent>
                  <QuickConnect />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} className="xyz-nested">
              <QuickLinks />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Overview;
