import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import useTheme from '@mui/material/styles/useTheme';

import {
  AccountHostingList200AccountsItem,
  HostingSitesV2200ItemsItem,
} from '@newfold/huapi-js/src/index.schemas';

import useThumbnail from '~/scenes/Site/scenes/Overview/useThumbnail';

import noSitePictureWide from '../../../../../../Sites/assets/placeholders/site-no-picture-wide.svg';

const thumbnailBgColorOneOff = '#d8e0eb';
const aspectX = 16;
const aspectY = 9;

export const siteThumbnailSkeletonSx = {
  aspectRatio: `${aspectX}/${aspectY}`,
  width: '100%',
};

export const siteThumbnailSx = {
  ...siteThumbnailSkeletonSx,
  backgroundColor: thumbnailBgColorOneOff,
};

interface SiteThumbnailPropOptions extends HostingSitesV2200ItemsItem {
  hosting: AccountHostingList200AccountsItem;
}

const SiteThumbnail = ({
  type,
  detected_type,
  url,
}: SiteThumbnailPropOptions) => {
  const theme = useTheme();

  const isWordPress = type === 'wordpress';
  const detectedWordPress = detected_type === 'wordpress';

  const isBrokenWordPress = (isWordPress && !detectedWordPress) || !url;

  const { thumbnailLoc, thumbnailType, isInitialLoading } = useThumbnail(
    url,
    false,
    !isBrokenWordPress,
  );

  if (isInitialLoading) {
    return (
      <Box
        data-testid="site-thumbnail-skeleton"
        sx={{
          ...siteThumbnailSkeletonSx,
          border: `1px solid ${theme.palette.grey[400]}`,
        }}
      >
        <Skeleton variant="rectangular" height={'100%'} />
      </Box>
    );
  }

  const imgSrc = (() => {
    if (!thumbnailLoc) return noSitePictureWide;
    if (thumbnailType === 'Base64') {
      return `data:image/png;base64,${thumbnailLoc}`;
    }
    return thumbnailLoc;
  })();

  return (
    <Box
      data-testid="site-thumbnail"
      component="img"
      src={imgSrc}
      sx={{
        ...siteThumbnailSx,
        border: `1px solid ${theme.palette.grey[400]}`,
      }}
    />
  );
};

export default SiteThumbnail;
